// PRODUCT CATEGORY TYPES ARE USED FOR DATALAYER
const PRODUCT_CATEGORIES = {
  DISCOUNT: 'discount',
  EXTRA: 'extra',
  MAIN: 'main',
  PAYMENT: 'payment',
  PAYMENT_RELATED: 'payment related',
  SERVICE_CC: 'service',
  SERVICE_PACKAGE: 'service package'
};
export const AIR_HELP = {
  id: 5102,
  name: 'airHelp',
  productCategoryType: PRODUCT_CATEGORIES.EXTRA
};
export const ALL_INCLUSIVE_PROTECTION = {
  id: 278,
  name: 'allInclusiveProtection',
  productCategoryType: PRODUCT_CATEGORIES.EXTRA
};
export const BAGGAGE_INSURANCE_COMBO_COVER_GENIUS = {
  id: 615,
  name: 'baggageInsuranceComboCoverGenius',
  iconId: 'luggage-gadget',
  productCategoryType: PRODUCT_CATEGORIES.EXTRA
};
export const BAGGAGE_INSURANCE_COVER_GENIUS = {
  id: 612,
  name: 'baggageInsuranceCoverGenius',
  iconId: 'luggage-blank',
  productCategoryType: PRODUCT_CATEGORIES.EXTRA
};
export const BAGGAGE_INSURANCE_GADGET_COVER_GENIUS = {
  id: 616,
  name: 'baggageInsuranceGadgetCoverGenius',
  iconId: 'gadget',
  productCategoryType: PRODUCT_CATEGORIES.EXTRA
};
export const BAGGAGE_SERVICE = {
  id: 270,
  name: 'baggageService',
  productCategoryType: PRODUCT_CATEGORIES.EXTRA
};
export const BANKRUPTCY_INSURANCE_COVER_GENIUS = {
  id: 621,
  name: 'bankruptcyInsuranceCoverGenius',
  iconId: 'bankruptcy-insurance',
  productCategoryType: PRODUCT_CATEGORIES.EXTRA
};
export const CABIN_BAGGAGE = {
  id: 625,
  name: 'cabinBaggage',
  productCategoryType: PRODUCT_CATEGORIES.EXTRA
};
export const CANCEL_24_HOURS = {
  id: 628,
  name: 'cancelTwentyFourHours',
  productCategoryType: PRODUCT_CATEGORIES.EXTRA
};
export const CANCEL_FOR_ANY_REASON = {
  id: 627,
  name: 'cancelForAnyReason',
  productCategoryType: PRODUCT_CATEGORIES.EXTRA
};
export const CANCELLATION_GUARANTEE = {
  id: 280,
  name: 'cancellationGuarantee',
  productCategoryType: PRODUCT_CATEGORIES.EXTRA
};
export const CANCELLATION_INSIDE_EU = {
  id: 40,
  name: 'cancellationInsideEu',
  productCategoryType: PRODUCT_CATEGORIES.EXTRA
};
export const CANCELLATION_INSURANCE_COVER_GENIUS = {
  id: 611,
  name: 'cancellationInsuranceCoverGenius',
  iconId: 'share',
  productCategoryType: PRODUCT_CATEGORIES.EXTRA
};
export const CANCELLATION_OUTSIDE_EU = {
  id: 41,
  name: 'cancellationOutsideEu',
  productCategoryType: PRODUCT_CATEGORIES.EXTRA
};
export const CHECK_IN_BAGGAGE = {
  id: 6,
  name: 'checkInBaggage',
  productCategoryType: PRODUCT_CATEGORIES.EXTRA
};
export const CLIMATE_COMPENSATION = {
  id: 261,
  name: 'climateCompensation',
  productCategoryType: PRODUCT_CATEGORIES.EXTRA
};
export const COMPREHENSIVE_INSURANCE_COVER_GENIUS = {
  id: 281,
  name: 'comprehensiveInsuranceCoverGenius',
  iconId: 'shield',
  productCategoryType: PRODUCT_CATEGORIES.EXTRA
};
export const CONNECTION_GUARANTEE = {
  id: 274,
  name: 'connectionGuarantee',
  productCategoryType: PRODUCT_CATEGORIES.EXTRA
};
export const CO2_COMPENSATION = {
  id: 629,
  name: 'co2Compensation',
  productCategoryType: PRODUCT_CATEGORIES.EXTRA
};
export const FLEXIBLE_TICKET = {
  id: 65,
  name: 'flexibleTicket',
  productCategoryType: PRODUCT_CATEGORIES.EXTRA
};
export const MEAL = {
  id: 260,
  name: 'meal',
  productCategoryType: PRODUCT_CATEGORIES.EXTRA
};
export const MOBILE_TRAVEL_PLAN = {
  id: 83,
  name: 'mobileTravelPlan',
  productCategoryType: PRODUCT_CATEGORIES.EXTRA
};
export const ONLINE_CHECK_IN = {
  id: 5003,
  name: 'onlineCheckIn',
  productCategoryType: PRODUCT_CATEGORIES.EXTRA
};
export const SEAT_MAP = {
  id: 2003,
  name: 'seatMap',
  productCategoryType: PRODUCT_CATEGORIES.EXTRA
};
export const SEATING_BESIDES = {
  id: 2000,
  name: 'seatingBesides',
  productCategoryType: PRODUCT_CATEGORIES.EXTRA
};
export const SEATING_LIGHT = {
  id: 2001,
  name: 'seatingLight',
  productCategoryType: PRODUCT_CATEGORIES.EXTRA
};
export const SERVICE_PACKAGE = {
  id: 62,
  name: 'servicePackage',
  productCategoryType: PRODUCT_CATEGORIES.EXTRA
};
export const SERVICE_PACKAGE_NO_TEXT = {
  id: 64,
  name: 'servicePackageNoText',
  productCategoryType: PRODUCT_CATEGORIES.EXTRA
};
export const SERVICE_PACKAGE_REQUEST = {
  id: 201,
  name: 'servicePackageRequest',
  productCategoryType: PRODUCT_CATEGORIES.SERVICE_CC
};
export const SIMPLE_VISA = {
  id: 279,
  name: 'simpleVisa',
  productCategoryType: PRODUCT_CATEGORIES.EXTRA
};
export const SMS = {
  id: 2002,
  name: 'sms',
  productCategoryType: PRODUCT_CATEGORIES.EXTRA
};
export const TRAVEL_DOCS_POST = {
  id: 63,
  name: 'travelDocsPost',
  productCategoryType: PRODUCT_CATEGORIES.EXTRA
};
export const TRAVEL_INSURANCE_COVER_GENIUS = {
  id: 613,
  name: 'travelInsuranceCoverGenius',
  iconId: 'plane-bold',
  productCategoryType: PRODUCT_CATEGORIES.EXTRA
};
export const TRAVEL_INSURANCE_INSIDE_EU = {
  id: 87,
  name: 'travelInsuranceInsideEu',
  productCategoryType: PRODUCT_CATEGORIES.EXTRA
};
export const TRAVEL_INSURANCE_OUTSIDE_EU = {
  id: 86,
  name: 'travelInsuranceOutsideEu',
  productCategoryType: PRODUCT_CATEGORIES.EXTRA
};
export const TRIP_CANCELLATION_PROTECTION = {
  id: 277,
  name: 'tripCancellationProtection',
  productCategoryType: PRODUCT_CATEGORIES.EXTRA
};
export const SECOND_BAGGAGE = {
  id: 631,
  name: 'secondBaggagePerTraveler',
  productCategoryType: PRODUCT_CATEGORIES.EXTRA
};
export const FAST_TRACK = {
  id: 632,
  name: 'fastTrack',
  productCategoryType: PRODUCT_CATEGORIES.EXTRA
};
export const PLATINUM_SERVICE = {
  id: 5001,
  name: 'platinum',
  productCategoryType: PRODUCT_CATEGORIES.EXTRA
};
export const QPASS = {
  id: 633,
  name: 'qPass',
  productCategoryType: PRODUCT_CATEGORIES.EXTRA
};