import { price as priceUtils } from '@eti/utils';
import { ProductCardModalType } from '../models/product';
import { UPSELL_TYPES } from './constants';
import { formatUpsell } from './upsellParse';
import { isPrimaryFlow } from './primaryFlow';
import { getPriceDivided as getPricePerBound } from './price';
export const getUpsellProps = upSellText => {
  const upSellObject = formatUpsell(upSellText);
  const shouldDisplayUpsell = upSellObject && upSellObject.content !== '';
  const isUpsellHighlight = shouldDisplayUpsell && (upSellObject === null || upSellObject === void 0 ? void 0 : upSellObject.type) === UPSELL_TYPES.PROMOTIONAL;
  return {
    upSellObject,
    isUpsellHighlight,
    shouldDisplayUpsell
  };
};
export const getPriceProps = (t, isSelected, options) => {
  const {
    priceRaw,
    numberOfBounds,
    shouldShowPricePerBound: isPerBound,
    shouldShowPricePerTraveler: isPerTraveler
  } = options;
  const perBoundsLabel = t('Products.Price.Label.PerBounds');
  const perTravelerLabel = t('Products.Price.Label.Person');
  const perBoundsAndTravelerLabel = t('Product.Price.Per.Bound.And.Person');
  const price = priceUtils.formatPrice(isPerBound ? getPricePerBound(priceRaw, numberOfBounds) : priceRaw);
  const onlyLabel = t('Products.Price.Label.Only');
  const totalLabel = t('Products.Price.Label.Total');
  const priceLabel = isSelected ? totalLabel : onlyLabel;
  let priceSuffix = '';
  if (isPerTraveler && !isPerBound) {
    priceSuffix = perTravelerLabel;
  } else if (!isPerTraveler && isPerBound && numberOfBounds && perBoundsLabel) {
    priceSuffix = perBoundsLabel;
  } else if (isPerTraveler && isPerBound) {
    priceSuffix = perBoundsAndTravelerLabel;
  }
  return {
    priceLabel,
    price,
    priceSuffix
  };
};
export const getSingleChoiceProps = (t, p, nonDebugT) => {
  const shouldUseValidationLabel = p('IbeClient.TravelerDetails.OptionalProductsRequireValidation.Enabled');
  const isMainFlow = isPrimaryFlow();
  const label = t('Product.SelectOne.Label');
  const selectOneLabelMainFlow = shouldUseValidationLabel ? nonDebugT('Input.Field.Required', label) : '';
  const selectOneLabel = isMainFlow ? selectOneLabelMainFlow : '';
  const labelNo = t('Product.AncillaryRadio.Label.No');
  const labelYes = t('Product.AncillaryRadio.Label.Yes');
  return {
    selectOneLabel,
    labelNo,
    labelYes
  };
};
export const getReadMoreLabel = t => t('Products.ReadMore.Link.Label');
export const getModal = (showPersuasion, confirmationContent, persuasionContent) => {
  const hasPersuasion = Boolean(persuasionContent);
  const hasConfirmation = Boolean(confirmationContent);
  const productCardModal = {
    content: '',
    type: ProductCardModalType.None
  };
  if (hasConfirmation) {
    productCardModal.content = confirmationContent;
    productCardModal.type = ProductCardModalType.Confirmation;
  } else if (showPersuasion && hasPersuasion) {
    productCardModal.content = persuasionContent;
    productCardModal.type = ProductCardModalType.Persuasion;
  }
  return productCardModal;
};