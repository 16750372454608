export const getTravelersPrimaryFlow = order => {
  var _order$sellSpecificat, _order$sellSpecificat2, _order$travelers;
  const baggageSpecification = (_order$sellSpecificat = order === null || order === void 0 || (_order$sellSpecificat2 = order.sellSpecification) === null || _order$sellSpecificat2 === void 0 ? void 0 : _order$sellSpecificat2.sellPriceBaggage) !== null && _order$sellSpecificat !== void 0 ? _order$sellSpecificat : [];
  return order === null || order === void 0 || (_order$travelers = order.travelers) === null || _order$travelers === void 0 ? void 0 : _order$travelers.map((traveler, index) => {
    var _order$persuasion, _price$markup$value, _price$markup, _price$price;
    const isPersuasionEnabled = index === 0 && (order === null || order === void 0 || (_order$persuasion = order.persuasion) === null || _order$persuasion === void 0 ? void 0 : _order$persuasion.isEnabled);
    const {
      price
    } = (baggageSpecification === null || baggageSpecification === void 0 ? void 0 : baggageSpecification.find(currTraveler => String(currTraveler === null || currTraveler === void 0 ? void 0 : currTraveler.travelerId) === String(traveler === null || traveler === void 0 ? void 0 : traveler.id))) || {};
    return {
      ...traveler,
      id: (traveler === null || traveler === void 0 ? void 0 : traveler.id) || '',
      persuasionEnabled: isPersuasionEnabled,
      productPreSelection: order.productPreSelection || '',
      markup: (_price$markup$value = price === null || price === void 0 || (_price$markup = price.markup) === null || _price$markup === void 0 ? void 0 : _price$markup.value) !== null && _price$markup$value !== void 0 ? _price$markup$value : null,
      price: price === null || price === void 0 || (_price$price = price.price) === null || _price$price === void 0 ? void 0 : _price$price.value
    };
  });
};
export const getTravelersPostbooking = (productId, order) => {
  var _order$availableExtra, _baggageProduct$sellS, _order$travelers2;
  const baggageProduct = order === null || order === void 0 || (_order$availableExtra = order.availableExtraProducts) === null || _order$availableExtra === void 0 ? void 0 : _order$availableExtra.find(product => String(product === null || product === void 0 ? void 0 : product.id) === String(productId));
  const baggageSpecification = (baggageProduct === null || baggageProduct === void 0 || (_baggageProduct$sellS = baggageProduct.sellSpecification) === null || _baggageProduct$sellS === void 0 ? void 0 : _baggageProduct$sellS.sellPriceBaggage) || [];
  const eligibleTravelerIdsForBaggage = baggageSpecification.flatMap(product => product === null || product === void 0 ? void 0 : product.travelerId).filter(Boolean) || [];
  return order === null || order === void 0 || (_order$travelers2 = order.travelers) === null || _order$travelers2 === void 0 || (_order$travelers2 = _order$travelers2.filter(_ref => {
    let {
      id
    } = _ref;
    return id !== undefined && eligibleTravelerIdsForBaggage.length && (eligibleTravelerIdsForBaggage === null || eligibleTravelerIdsForBaggage === void 0 ? void 0 : eligibleTravelerIdsForBaggage.includes(String(id)));
  })) === null || _order$travelers2 === void 0 ? void 0 : _order$travelers2.map(traveler => {
    var _price$markup$value2, _price$markup2, _price$price2;
    const {
      price
    } = (baggageSpecification === null || baggageSpecification === void 0 ? void 0 : baggageSpecification.find(currTraveler => String(currTraveler === null || currTraveler === void 0 ? void 0 : currTraveler.travelerId) === String(traveler === null || traveler === void 0 ? void 0 : traveler.id))) || {};
    return {
      ...traveler,
      name: (traveler === null || traveler === void 0 ? void 0 : traveler.fullName) || '',
      markup: (_price$markup$value2 = price === null || price === void 0 || (_price$markup2 = price.markup) === null || _price$markup2 === void 0 ? void 0 : _price$markup2.value) !== null && _price$markup$value2 !== void 0 ? _price$markup$value2 : null,
      price: price === null || price === void 0 || (_price$price2 = price.price) === null || _price$price2 === void 0 ? void 0 : _price$price2.value
    };
  });
};
export const getBaggageWeight = _ref2 => {
  let {
    numberOfPieces,
    weight,
    weightUnit,
    texts,
    t
  } = _ref2;
  if (numberOfPieces && weight && weightUnit) {
    const {
      pieces,
      weightDescription,
      weightValues
    } = texts;
    const baggageWeightValues = `${t(pieces, numberOfPieces)} x ${t(weightValues, weight, weightUnit)}`;
    return t(weightDescription, baggageWeightValues);
  }
  return '';
};