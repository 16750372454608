import { gql } from '@eti/schema-types';

export const MAKE_ORDER_WITH_BANK_PAYMENT = gql(/* GraphQL */ `
  mutation MakeOrderWithBankPayment(
    $customer: CustomerInput!
    $bank: BankInput!
    $cartId: Long
    $forterToken: String
  ) {
    makeOrderWithBankPayment(
      customer: $customer
      bank: $bank
      cartId: $cartId
      forterToken: $forterToken
    ) {
      success
      type
      message
      redirectURL
      postArgs {
        name
        value
      }
      tripChanges {
        ... on TripPriceChange {
          id
          priceOld {
            price {
              value
            }
          }
          priceNew {
            price {
              value
            }
          }
          priceDiff {
            price {
              value
            }
          }
        }
      }
    }
  }
`);

export const MAKE_ORDER_WITH_CARD_PAYMENT_MUTATION = gql(/* GraphQL */ `
  mutation MakeOrderWithCardPayment(
    $pmopuuid: String!
    $customer: CustomerInput!
    $creditCard: CreditCardInput!
    $encryptedData: String
    $ref: String
    $cartId: Long
    $paymentOptions: PaymentOptionsInput
    $forterToken: String
    $priceChangeDisplayed: String
    $ubaInfo: UbaInfoInput
    $deviceInfo: DeviceInfo
  ) {
    makeOrderWithCardPayment(
      pmopuuid: $pmopuuid
      customer: $customer
      creditCard: $creditCard
      encryptedData: $encryptedData
      ref: $ref
      cartId: $cartId
      paymentOptions: $paymentOptions
      forterToken: $forterToken
      priceChangeDisplayed: $priceChangeDisplayed
      ubaInfo: $ubaInfo
      deviceInfo: $deviceInfo
    ) {
      success
      type
      message
      redirectURL
      postArgs {
        name
        value
      }
      tripChanges {
        ... on TripPriceChange {
          id
          priceOld {
            price {
              value
            }
          }
          priceNew {
            price {
              value
            }
          }
          priceDiff {
            price {
              value
            }
          }
        }
      }
    }
  }
`);

export const MAKE_ORDER_WITH_WALLET_PAYMENT = gql(/* GraphQL */ `
  mutation MakeOrderWithWalletPayment(
    $customer: CustomerInput!
    $wallet: WalletInput!
    $cartId: Long
    $forterToken: String
    $priceChangeDisplayed: String
    $ubaInfo: UbaInfoInput
  ) {
    makeOrderWithWalletPayment(
      customer: $customer
      wallet: $wallet
      cartId: $cartId
      forterToken: $forterToken
      priceChangeDisplayed: $priceChangeDisplayed
      ubaInfo: $ubaInfo
    ) {
      success
      type
      message
      redirectURL
      postArgs {
        name
        value
      }
      tripChanges {
        ... on TripPriceChange {
          id
          priceOld {
            price {
              value
            }
          }
          priceNew {
            price {
              value
            }
          }
          priceDiff {
            price {
              value
            }
          }
        }
      }
    }
  }
`);

export const MAKE_ORDER_WITH_PAY_INTERNAL_PAYMENT = gql(/* GraphQL */ `
  mutation MakeOrderWithPayInternalPayment(
    $customer: CustomerInput!
    $cartId: Long
    $forterToken: String
    $ubaInfo: UbaInfoInput
  ) {
    makeOrderWithPayInternalPayment(
      customer: $customer
      cartId: $cartId
      forterToken: $forterToken
      ubaInfo: $ubaInfo
    ) {
      message
      type
      tripChanges {
        ... on TripPriceChange {
          id
          priceOld {
            price {
              value
            }
          }
          priceNew {
            price {
              value
            }
          }
          priceDiff {
            price {
              value
            }
          }
        }
      }
    }
  }
`);

export const CANCEL_MAKE_ORDER_MUTATION = gql(/* GraphQL */ `
  mutation CancelMakeOrder {
    cancelMakeOrder {
      success
    }
  }
`);

export const REJECT_PRICE_CHANGE = gql(/* GraphQL */ `
  mutation RejectPriceChange {
    rejectPriceChange {
      success
    }
  }
`);

export const STORE_STATISTICS_MUTATION = gql(/* GraphQL */ `
  mutation StoreStatistics(
    $offeredProducts: [ID!]
    $brandedFareOffered: Boolean
    $paymentRequest: Boolean
  ) {
    storeStatistics(
      offeredProducts: $offeredProducts
      brandedFareOffered: $brandedFareOffered
      paymentRequest: $paymentRequest
    )
  }
`);

export const REMOVE_DISCOUNTS = gql(/* GraphQL */ `
  mutation removeDiscounts {
    removeDiscounts {
      success
    }
  }
`);

export const ADD_DISCOUNT = gql(/* GraphQL */ `
  mutation addDiscountCode($discountCode: String!) {
    addDiscountCode(discountCode: $discountCode) {
      success
      discountPrice {
        price {
          value
        }
      }
      discountErrorMessage {
        headerText
        messages
      }
    }
  }
`);
