import { connect } from 'react-redux';
import { CHECK_IN_BAGGAGE } from '../../products';
import CheckInBaggagePrimaryFlow from '../components/CheckInBaggagePrimaryFlow';
import { setChoiceValueForTraveler, setCombinedChoiceValueForTraveler } from '../../common/components/baggage-per-traveler/utils/baggagePerTravelerUtil';
import { getSelectedForTraveler, getSameAsFirstTravelerForCurrentTraveler } from '../../common/utils/baggage/units';
const productName = CHECK_IN_BAGGAGE.name;
const mapStateToProps = (state, _ref) => {
  var _order$travelers;
  let {
    form,
    travelerNumber,
    order
  } = _ref;
  const firstTravelerId = order === null || order === void 0 || (_order$travelers = order.travelers) === null || _order$travelers === void 0 || (_order$travelers = _order$travelers[0]) === null || _order$travelers === void 0 ? void 0 : _order$travelers.id;
  return {
    firstTravelerSelectionValue: getSelectedForTraveler(productName, form, true)(state, firstTravelerId),
    hasSameChoiceAsFirstTraveler: getSameAsFirstTravelerForCurrentTraveler(productName, form)(state, travelerNumber),
    productName
  };
};
const mapDispatchToProps = (dispatch, props) => ({
  setChoiceValue: setChoiceValueForTraveler(dispatch, productName, props),
  setCombinedChoiceValue: setCombinedChoiceValueForTraveler(dispatch, productName, props)
});
export default connect(mapStateToProps, mapDispatchToProps)(CheckInBaggagePrimaryFlow);