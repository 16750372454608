export default {
  ADR: 'adr',
  AGENCYAUTHENTICATED: 'agencyauthenticated',
  AZULMUNDO: 'azulmundo',
  AIRTICKETS24: 'airtickets24',
  AVION: 'avion',
  BOOKBARBADOS: 'bookbarbados',
  BOOKING: 'booking',
  BUDJET: 'budjet',
  BUSINESSCLASS: 'businessclass',
  FANTASTICGREECE: 'fantasticgreece',
  FLIGHTNETWORK: 'flightnetwork',
  FLY: 'fly',
  FLYBILLET: 'flybillet',
  FLYFAR: 'flyfar',
  FLYGVARUHUSET: 'flygvaruhuset',
  GOLEIF: 'goleif',
  GOTOGATE: 'gotogate',
  HOTELIERMART: 'hoteliermart',
  MYTRIP: 'mytrip',
  NOGKO: 'nokgo',
  NUSTAY: 'nustay',
  PAMEDIAKOPES: 'pamediakopes',
  RADISSONHOTELS: 'radissonhotels',
  ROME2RIO: 'rome2rio',
  RCI: 'rci',
  SEAT24: 'seat24',
  SERKO: 'serko',
  SOUTHWEST: 'southwest',
  SUBIRTE: 'subirte',
  SUPERSAVER: 'supersaver',
  TRAVELFINDER: 'travelfinder',
  TRAVELPARTNER: 'travelpartner',
  TRAVELSTART: 'travelstart',
  TRAVELVENTURA: 'travelventura',
  TRIP: 'trip',
  TRIPBEAT: 'tripbeat',
  TRIPSTACK: 'tripstack',
  TUI: 'tui',
  WYNDHAM: 'wyndham'
};