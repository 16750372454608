import { AIR_HELP, ALL_INCLUSIVE_PROTECTION, BAGGAGE_SERVICE, CABIN_BAGGAGE, CANCEL_FOR_ANY_REASON, CANCELLATION_GUARANTEE, CANCELLATION_INSIDE_EU, CANCELLATION_OUTSIDE_EU, CHECK_IN_BAGGAGE, CLIMATE_COMPENSATION, CONNECTION_GUARANTEE, FLEXIBLE_TICKET, MEAL, MOBILE_TRAVEL_PLAN, ONLINE_CHECK_IN, PLATINUM_SERVICE, SEAT_MAP, SEATING_BESIDES, SEATING_LIGHT, SECOND_BAGGAGE, SERVICE_PACKAGE_NO_TEXT, SERVICE_PACKAGE, SIMPLE_VISA, SMS, TRAVEL_DOCS_POST, TRAVEL_INSURANCE_INSIDE_EU, TRAVEL_INSURANCE_OUTSIDE_EU, TRIP_CANCELLATION_PROTECTION } from '../../products';
import getAirHelpIcon from '../../air-help/utils/icon';
import getBaggageServiceIcon from '../../baggage-service/utils/icon';
import getCabinBaggageIcon from '../../baggage-section/cabin-baggage/utils/icon';
import getCancelForAnyReasonIcon from '../../cancel-for-any-reason/utils/icon';
import getCancellationIcon from '../utils/icon/cancelation-icon';
import getCheckInBaggageIcon from '../../check-in-baggage/utils/icon';
import getClimateCompensationIcon from '../../climate-compensation/utils/icon';
import getConnectionGuaranteeIcon from '../../connection-guarantee/utils/icon';
import getDefaultIcon from '../utils/icon/defaultProductIcon';
import getFlexibleTicketIcon from '../../flexible-ticket/utils/icon';
import getManulifeIcon from '../../manulife/common/utils/icon';
import getMealIcon from '../../meal/utils/icon';
import getMobileTravelPlanIcon from '../../mobile-travel-plan/utils/icon';
import getOnlineCheckInIcon from '../../online-check-in/utils/icon';
import getSeatingIcon from '../../seating-besides/utils/icon';
import getServicePackageIcon from '../../service-package/utils/icon';
import getSimpleVisaIcon from '../../simple-visa/utils/icon';
import getSmsIcon from '../../sms/utils/icon';
import getTravelDocsByPostIcon from '../../travel-docs-post/utils/icon';
import getTravelInsuranceIcon from '../../travel-insurance/common/utils/icon';
export default ((id, type) => {
  switch (id) {
    case CANCEL_FOR_ANY_REASON.name:
      return getCancelForAnyReasonIcon(type);
    case AIR_HELP.name:
      return getAirHelpIcon(type);
    case BAGGAGE_SERVICE.name:
      return getBaggageServiceIcon(type);
    case CANCELLATION_GUARANTEE.name:
    case CANCELLATION_INSIDE_EU.name:
    case CANCELLATION_OUTSIDE_EU.name:
      return getCancellationIcon(type);
    case CHECK_IN_BAGGAGE.name:
      return getCheckInBaggageIcon(type);
    case SECOND_BAGGAGE.name:
      return getCheckInBaggageIcon(type);
    case CLIMATE_COMPENSATION.name:
      return getClimateCompensationIcon(type);
    case CONNECTION_GUARANTEE.name:
      return getConnectionGuaranteeIcon(type);
    case FLEXIBLE_TICKET.name:
      return getFlexibleTicketIcon(type);
    case MEAL.name:
      return getMealIcon(type);
    case MOBILE_TRAVEL_PLAN.name:
      return getMobileTravelPlanIcon(type);
    case ONLINE_CHECK_IN.name:
      return getOnlineCheckInIcon(type);
    case PLATINUM_SERVICE.name:
      return getServicePackageIcon(type);
    case SEAT_MAP.name:
      return getSeatingIcon(type);
    case SEATING_BESIDES.name:
      return getSeatingIcon(type);
    case SEATING_LIGHT.name:
      return getSeatingIcon(type);
    case SERVICE_PACKAGE.name:
    case SERVICE_PACKAGE_NO_TEXT.name:
      return getServicePackageIcon(type);
    case SMS.name:
      return getSmsIcon(type);
    case TRAVEL_DOCS_POST.name:
      return getTravelDocsByPostIcon(type);
    case TRAVEL_INSURANCE_INSIDE_EU.name:
    case TRAVEL_INSURANCE_OUTSIDE_EU.name:
      return getTravelInsuranceIcon(type);
    case TRIP_CANCELLATION_PROTECTION.name:
    case ALL_INCLUSIVE_PROTECTION.name:
      return getManulifeIcon(type);
    case SIMPLE_VISA.name:
      return getSimpleVisaIcon(type);
    case CABIN_BAGGAGE.name:
      return getCabinBaggageIcon(type);
    default:
      return getDefaultIcon(type);
  }
});